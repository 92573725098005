window.slideshowData = function (mediaGallery) {
  "use strict";

  $('.dot-slider').on('click', function (evt) {
    evt.stopPropagation();

    let id = $(evt.target).data('id');
    $('.dot-slider').removeClass('active');
    $(this).addClass('active');
    let mediaClicked = mediaGallery.find(x => x.id === id);

    if (mediaClicked.fullUrl != null) {
      let tempTemplate = bindSlider(mediaClicked);
      $('.full-height.not-completely-full').empty().append(tempTemplate);

      $('.tempClass').css({
        opacity: 0,
        transform: 'translateY(40px)',
        transition: 'all 2s cubic-bezier(0.19, 1, 0.22, 1) 0.3s'
      })

      setTimeout(() => {
        $('.tempClass').css({
          opacity: 1,
          transform: 'translateY(0)',
          transitionDelay: '0.2s'
        })

        $('.tempClass').addClass('animatedblock delay2 animatedUp');
      }, 250);
    }

  })

}

function bindSlider(media) {
  let template = '';
  template = `
      <div class="fixed">
        <figure class="background-image3 parallax parallax-banner">
          <img src="${media.fullUrl}" alt="${media.title}" style="height: 100%; object-fit: cover;"/>
        </figure>
      </div><!--.fixed-->

      <div class="full-height-wrapper white-text">
        <div class="parent">
          <div class="bottom">
            <div class="container">
              <div class="tempClass">
                <div class="col-lg-6 col-md-offset-1 col-md-7">
                  <div class="banner-textblock">
                    <p class="large">${media.title ?? ''}</p>
                    <h1 class="header">${media.subtitle ?? ''}</h1>
                  </div><!--.banner-textblock-->
                </div><!--.col-lg-6.col-md-offset-1.col-md-7-->
              </div><!--.tempClass .animatedblock.delay2.animatedUp-->
            </div><!-- .container -->
          </div><!--.bottom-->
        </div><!--.parent-->
        <div class="overlay"></div><!--.overlay-->
      </div><!--.full-height-wrapper.white-text-->
`;

  return template;
}


